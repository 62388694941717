<template>
  <el-tabs
    v-model="activeIndex"
    type="card"
    closable
    class="demo-tabs"
    @tab-remove="handleTabsRemove"
    @tab-click="tabChange"
  >
    <el-tab-pane v-for="item in activeMenu" :key="item.name" :label="item.title" :name="item.name" />
  </el-tabs>
</template>
<script setup>
import { useMenu } from '@/store/menu'
import { storeToRefs } from 'pinia'
import { useRouter } from 'vue-router'

const menu = useMenu()
const router = useRouter()
const { activeMenu, activeIndex } = storeToRefs(menu)

const handleTabsRemove = (targetName) => {
  if (activeMenu.value.length > 1) {
    menu.deleteMenu(targetName)
    const lastPage = activeMenu.value[activeMenu.value.length - 1].name
    const params = activeMenu.value[activeMenu.value.length - 1].params
    router.push({ name: lastPage, params })
    menu.reloadStart(targetName)
  }
}

const tabChange = (pane) => {
  const params = activeMenu.value.find((item) => item.name == pane.props.name).params

  router.push({
    name: pane.props.name,
    params,
  })
}
</script>
<style scoped>
.demo-tabs{
  user-select:none
}
::v-deep .demo-tabs > .el-tabs__content {
  color: #6b778c;
  font-size: 32px;
  font-weight: 600;
}
::v-deep .el-tabs__item.is-active{
  background-color: rgb(24, 144, 255);
  color: #fff;
  /* border: 3px solid rgb(24, 144, 255); */
}
</style>
