import { defineStore } from 'pinia'
import { useCache } from '@/hooks/useCache.js'

const { wsCache } = useCache()
export const useMenu = defineStore('menu', {
  state: () => {
    return {
      // 目录
      importMenu: [
        {
          name: 'importNoTicket',
          title: '无票免税',
          children: [
            {
              title: '无票免税',
              name: 'importTicket',
            },
          ],
        },
        {
          name: 'importApproved',
          title: '所得税核定征收',
          children: [
            {
              title: '核定征收',
              name: 'importCollection',
            },
          ],
        },
        {
          name: 'importTaxRefund',
          title: '出口退免税',
          children: [
            {
              title: '出口明细',
              name: 'importExport',
            },
            {
              title: '进货明细',
              name: 'importPurchasing',
            },
            {
              title: '退免税管理',
              name: 'importManage',
            },
            {
              title: '比对工具',
              name: 'importTool',
            },
          ],
        },
      ],
      activeIndex: 0,
      activeMenu: [],
      reloadMenu:[],
    }
  },
  // 相当于计算属性
  getters: {},
  //写异步或同步函数
  actions: {
    async setImportMenu() {
      wsCache.set('SideMenu', this.importMenu)
      return true
    },
    // 增加tabs
    pushMenu(val) {
      this.activeMenu.push(val)
    },
    // 删除tabs
    deleteMenu(val) {
      this.activeMenu = this.activeMenu.filter((item) => item.name != val)
    },
    // 清空tabs栏
    clearAll() {
      this.activeIndex = 0
      this.activeMenu = []
    },
    reloadStart(comp){
      this.reloadMenu.push(comp)
      setTimeout(() => {
        this.reloadMenu = []
      }, 100);
    },
  },
})
