import './public-path';
import '@/assets/css/global.css'
import 'element-plus/dist/index.css'
import { createApp, createVNode } from 'vue';
import { createRouter, createWebHistory } from 'vue-router';
import App from './App.vue';
import routes from './router';
import { createPinia } from 'pinia'
import ElementPlus from 'element-plus'
import locale from 'element-plus/lib/locale/lang/zh-cn'
import { close, start } from '@/utils/nprogress.js'
import { useCache } from '@/hooks/useCache.js'
import { useMenu } from '@/store/menu.js'
import { storeToRefs } from 'pinia'
import { Auth } from '@/api/public'
import * as Icons from '@element-plus/icons-vue'
import debounce from '@/directives/debounce.js';

let router = null;
let instance = null;
let history = null;

const pinia = createPinia()
const Icon = (props) => {
  const { icon } = props
  return createVNode(Icons[icon])
}

function render(props = {}) {
  const { container } = props;
  history = createWebHistory(window.__POWERED_BY_QIANKUN__ ? '/kjds-admin-web' : '/');
  router = createRouter({
    history,
    routes,
  });

  const { wsCache } = useCache()

  router.beforeEach((to, from, next) => {
    const Token = wsCache.get('Token')
    if (Token || to.path == '/login' || to.path == '/loading') {
      next()
    } else {
      next({ path: '/loading' })
    }

    // 设置顶部tab栏
    const menu = useMenu()
    const { activeMenu, activeIndex } = storeToRefs(menu)

    // 禁止生成tab的网页
    const banMenu = ['login', 'loading', 'import']
    if (!banMenu.includes(to.name)) {
      // 生成tab路由信息
      const menuObj = {
        name: to.name,
        title: to.meta.title,
        params: to.params,
      }
      if (activeMenu.value.find(el => el.name == to.name)) menu.deleteMenu(to.name)

      menu.pushMenu(menuObj)
      activeIndex.value = to.name
    }
    start()
  })

  router.afterEach(() => {
    close()
  })

  instance = createApp(App);
  instance.use(router);
  instance.use(pinia);
  instance.use(debounce);
  instance.use(ElementPlus, { locale })
  instance.mount(container ? container.querySelector('#app') : '#app');
  instance.component('Icon', Icon)
}

if (!window.__POWERED_BY_QIANKUN__) {
  render()
}

export async function bootstrap() {
  console.log('%c ', 'color: green;', 'vue3.0 app bootstraped');
}

function storeTest(props) {
  props.onGlobalStateChange &&
    props.onGlobalStateChange(
      (value) => {
        if(value.eventType == "getUserInfo"){
          const { wsCache } = useCache()
          wsCache.set('merchant_id', value.data.corpCertNum)
          wsCache.set('user_info', value.data)
          Auth(value.data).then(res => {
            if(res.data.is_signed == false){
              router.push('/register')
              }else{
                if(res.data.enterprise_type == 1){
                  wsCache.set('Token', 'import')
                  const menu = useMenu()
                  const waitInit = menu.setImportMenu()
                  if (waitInit) {
                    router.push('/import/export-declare')
                  }
                }else if(res.data.enterprise_type == 2){
                    router.push('/entry')
                }
              }
          })
        }
      },
      true,
    );
  props.setGlobalState &&
    props.setGlobalState({
      ignore: props.name,
      user: {
        name: props.name,
      },
    });
}

export async function mount(props) {
  storeTest(props);
  render(props);
  instance.config.globalProperties.$onGlobalStateChange = props.onGlobalStateChange;
  instance.config.globalProperties.$setGlobalState = props.setGlobalState;
}

export async function unmount() {
  instance.unmount();
  instance._container.innerHTML = '';
  instance = null;
  router = null;
  history.destroy();
}