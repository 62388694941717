import layout from '@/layout/index.vue'

const routes = [
  {
    path: '/',
    name: 'Root',
    redirect: '/loading',
  },
  {
    path: '/loading',
    name: 'loading',
    component: () => import('@/view/login/loading.vue'),
  },
  {
    path: '/import',
    name: 'import',
    component: layout,
  },
  // 路由
  {
    path: '/import/no-ticket',
    name: 'importNoTicket',
    meta: {
      title: '无票免征',
    },
    component: layout,
    children: [
      {
        path: '/import/ticket',
        name: 'importTicket',
        meta: {
          title: '无票免征',
        },
        component: () => import('@/view/import/NoTicket/Ticket/index.vue'),
      },
    ]
  },
  {
    path: '/import/approved',
    name: 'importApproved',
    meta: {
      title: '所得税核定征收',
    },
    component: layout,
    children: [
      {
        path: '/import/collection',
        name: 'importCollection',
        meta: {
          title: '核定征收',
        },
        component: () => import('@/view/import/Approved/Collection/index.vue'),
      },
    ]
  },
  {
    path: '/import/tax-refund',
    name: 'importTaxRefund',
    meta: {
      title: '出口退免税',
    },
    component: layout,
    children: [
      {
        path: '/import/export',
        name: 'importExport',
        meta: {
          title: '出口明细',
        },
        component: () => import('@/view/import/TaxRefund/Export/index.vue'),
      },
      {
        path: '/import/purchasing',
        name: 'importPurchasing',
        meta: {
          title: '进货明细',
        },
        component: () => import('@/view/import/TaxRefund/Purchasing/index.vue'),
      },
      {
        path: '/import/manage',
        name: 'importManage',
        meta: {
          title: '退免税管理',
        },
        component: () => import('@/view/import/TaxRefund/Manage/index.vue'),
      },
      {
        path: '/import/tool',
        name: 'importTool',
        meta: {
          title: '比对关系',
        },
        component: () => import('@/view/import/TaxRefund/Tool/index.vue'),
      },
    ]
  },
  
];

export default routes;
