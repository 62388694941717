<template>
  <div class="side">
    <el-aside width="220px">
      <el-scrollbar>
        <el-menu :collapse="false" :default-active="activeIndex">
          <template v-for="(item, index) in sideMenu">
            <!-- 二级路由 -->
            <el-sub-menu
              :index="item.name"
              v-if="item.children"
              :key="item.name"
            >
              <template #title>
                <el-icon><Icon :icon="_iconArr[Number(index)]" /></el-icon>
                {{ item.title }}
              </template>
              <!-- 三级路由 -->
              <template v-for="item_th in item.children">
                <el-sub-menu
                  :index="item_th.name"
                  v-if="item_th.children"
                  :key="item_th.name"
                >
                  <template #title>
                    {{ item_th.title }}
                  </template>
                  <el-menu-item
                    @click="routePush(subItem.name)"
                    :key="subItem.name"
                    :index="subItem.name"
                    v-for="subItem in item_th.children"
                  >
                    {{ subItem.title }}
                  </el-menu-item>
                </el-sub-menu>
                <el-menu-item
                  @click="routePush(item_th.name)"
                  :key="item_th.name + 1"
                  :index="item_th.name"
                  v-else
                  >{{ item_th.title }}</el-menu-item
                >
              </template>
            </el-sub-menu>
            <el-menu-item
              :index="item.name"
              :key="item"
              @click="routePush(item.name)"
              v-else
            >
              <template #title>
                <el-icon><Icon :icon="_iconArr[Number(index)]" /></el-icon>
                {{ item.title }}
              </template>
            </el-menu-item>
          </template>
        </el-menu>
      </el-scrollbar>
    </el-aside>
  </div>
</template>
<script setup>
import { useCache } from "@/hooks/useCache";
import { useMenu } from "@/store/menu";
import { storeToRefs } from "pinia";
import { reactive } from "vue";
import { useRouter } from "vue-router";
const menu = useMenu();
const { activeIndex } = storeToRefs(menu);

// 图标
const _iconArr = reactive([
  "Promotion",
  "Tickets",
  "Calendar",
  "Money",
  "CollectionTag",
  "OfficeBuilding",
]);
const { wsCache } = useCache();
const sideMenu = reactive(wsCache.get("SideMenu"));

const router = useRouter();
const routePush = (routeName) => {
  router.push({ name: routeName });
};
</script>

<style scoped>
.side {
  user-select: none;
}
.aside {
  height: calc(100vh - 60px);
}
.el-menu-item.is-active {
  background-color: rgba(24, 144, 255, 0.1);
  border-right: 3px solid rgb(24, 144, 255);
  color: rgb(24, 144, 255);
}
</style>
