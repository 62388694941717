import axios from 'axios'
import { useCache } from '@/hooks/useCache'
import { ElMessage } from 'element-plus'
import { useLoading } from "@/hooks/useLoading.js"
// 创建axios实例
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL, // 所有的请求地址前缀部分(没有后端请求不用写)
  timeout: 20000, // 请求超时时间(毫秒)
  withCredentials: true, // 异步请求携带cookie
  headers: {
    'Access-Control-Allow-Credentials': true,
  },
})
const loading = useLoading()
// request拦截器
service.interceptors.request.use(
  config => {
    loading.start()
    return config
  },
  error => {
    // 对请求错误做些什么
    Promise.reject(error)
  },
)

// response 拦截器
service.interceptors.response.use(
  response => {
    loading.end()
    // 对响应数据做点什么
    if (response.data.status == false) {
      ElMessage({grouping: true, showClose: true, message: response.data.msg || response.data.message || '请求失败', type: 'error'})
    } else {
      const { wsCache } = useCache()
      if(response.data.msg == '请登录'){
        ElMessage({grouping: true, showClose: true, message: response.data.msg || response.data.message || '登录失效，请重新登录', type: 'error'})
        wsCache.set('AdminName', '')
        wsCache.set('Token', '')
        wsCache.set('SideMenu', '')
        location.reload()
      }
      return response.config.responseType == 'blob' ? response : response.data;
    }
  },
  error => {
    loading.end()
    // 对响应错误做点什么
    ElMessage({grouping: true, showClose: true, message: error.response.data.msg || error.response.data.message || '请求失败', type: 'error'})
    return Promise.reject(error)
  },
)
export { service }
